@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"












































.result-card--final

  .result-card__badge
    display: flex
    align-items: center
    justify-content: center
    width: 72px
    height: 72px
    border-radius: 8px
    color: white
    margin-bottom: 40px

    &-icon
      height: 40px
      width: 40px

  &:hover
    cursor: pointer
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.35)

